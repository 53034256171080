import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (group) {
    try {
        const params = { group };
        const res = await axios.get(Env.apiPath + "listImage", { params });
        if (res.data.error) {
            return null;
        }
        return res.data.list;
    } catch (err) {
        return null;
    }
}