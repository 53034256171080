<template>
    <div class="pt-32 flex flex-col items-center px-4">
        <van-cell-group class="md:w-1/2 w-full mb-8 shadow-md" inset>
            <van-cell :title="resources.length" />
        </van-cell-group>
        <van-cell-group class="md:w-1/2 w-full mb-8 shadow-md" inset v-for="(re, index) in resources" :key="index">
            <van-card :thumb="Env.filePath + re.key" :title="re.key" :desc="re.last">
                <template #price>
                    <div class="mt-4 flex flex-row justify-start items-center gap-8">
                        <a :href="Env.filePath + re.key" target="_blank">View</a>
                        <van-button type="danger" plain size="mini" @click="deleteImage(re.key)">Delete</van-button>
                    </div>
                </template>
            </van-card>
        </van-cell-group>
    </div>
</template>

<script>
import Env from "@/logics/Envs.js";
import {
    ref, onMounted
} from "vue";
import {
    useRoute
} from "vue-router";
import {
    CellGroup,
    Card,
    Button,
    Cell
} from "vant";
import ListImage from "@/asyncs/ListImage.js";
import DeleteImage from "@/asyncs/DeleteImage.js";

export default {
    components: {
        [Card.name]: Card,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Cell.name]: Cell
    },
    setup() {
        const route = useRoute();
        const resources = ref([]);
        onMounted(async () => {
            resources.value = await ListImage(route.params.group);
        });
        const deleteImage = async function (key) {
            const name = key.replace("classes/", "");
            if (!name) {
                return;
            }
            const ok = await DeleteImage("classes", name);
            if (ok) {
                resources.value = resources.value.filter(item => item.key !== key);
            }
        };
        return {
            Env,
            resources,
            deleteImage
        };
    }
}
</script>
