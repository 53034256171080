import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import Env from "@/logics/Envs.js";

export default async function (group, name) {
    try {
        const params = { group, name };
        await axios.get(Env.apiPath + "deleteImage", { params });
        return true;
    } catch (err) {
        CheckHttp(err);
        return false;
    }
}